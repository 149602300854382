@import url('https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap');

.thumbnail-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  font-family: 'Comic Neue', cursive;
}

.thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-family: 'Comic Neue', cursive;
}

.thumbnail img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease, filter 0.3s ease;
  filter: brightness(50%); /* Desaturates the image */
  opacity: 0;
}

.thumbnail:hover img {
  transform: scale(1.1);
  filter: brightness(100%); /* Returns the image to full saturation on hover */
}

h2 {
  font-size: 36px;
  margin: 0;
  font-family: 'Comic Neue', cursive;
}

h3 {
  font-size: 16px;
  font-family: 'Comic Neue', cursive;
}

/* Styles for mobile devices */
@media (max-width: 768px) {
  h2 {
    font-size: 28px; /* Smaller font size for h2 on mobile */
    font-family: 'Comic Neue', cursive;
  }

  h3 {
    font-size: 14px; /* Smaller font size for h3 on mobile */
    font-family: 'Comic Neue', cursive;
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  padding: 50px;
  min-width: 230px;
  border-radius: 5px;
  background-color: #a1bfff;
  color: #1a1a1a;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 0 20px rgba(0,0,0,1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Comic Neue', cursive;
}

.generateButton {
  width: 300px;
  height: 60px;
  border-radius: 5px;
  background-color: #1f3d64;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  margin-top: 20px;
  font-family: 'Comic Neue', cursive;
}

.generateButton:hover {
  background-color: #9711a3;
}

.create-button {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 140px;
  height: 120px;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #cc00cc;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  box-shadow: 0 0 20px rgba(0,0,0,1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  opacity: 0; /* Initially hide the button */
  animation: fadeIn 1s ease forwards; /* Apply the fade-in animation */
  animation-delay: 1s; /* Delay the animation start by 2 seconds */
  font-family: 'Comic Neue', cursive;
}

.discord-button {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10vh;
  border-radius: 10px;
  border: 1px solid #ffffff;
  background-color: #000000;
  box-shadow: 0 0 20px rgba(0,0,0,1);
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  opacity: 0; /* Initially hide the button */
  animation: fadeIn 1s ease forwards; /* Apply the fade-in animation */
  animation-delay: 1s; /* Delay the animation start by 2 seconds */
  font-family: 'Comic Neue', cursive;
}

.floating-button:hover {
  background-color: #ea00ff;
}

.loadingLabel {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  font-family: 'Comic Neue', cursive;
}

.spinner {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  display: inline-block;
  vertical-align: middle; /* Aligns spinner vertically with the text */
  margin-right: 15px; /* Adds some space between the spinner and the text */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

